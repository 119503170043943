














@import '@design';
.bluegiant-red-break {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: $color-bluegiant-red;
}
